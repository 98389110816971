.handwriting {
  transition: all 1s ease;
  transition-delay: 1s;
}
.handwriting svg {
  position: relative;
}
.handwriting svg .text {
  stroke-dasharray: 500;
  stroke-dashoffset: 500;
  animation: animate 10s linear 1 forwards;
}
@keyframes animate {
  to {
    stroke-dashoffset: 0;
  }
}
